import React, {useEffect, useState} from "react";

import PropTypes from 'prop-types';
import withRouter from './Common/withRouter';
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { authUser, sessionExpiration, sessionStorage } from "helpers/dataConfig";
import moment from "moment";

const NonAuthLayout = (props) => {

 


  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(NonAuthLayout);
