import axios from "axios";
import { sessionExpiration, sessionStorage } from "./dataConfig";
import MockAdapter from "axios-mock-adapter";
import { Auth } from 'aws-amplify';
import moment from "moment";
// import accessToken from "./jwt-token-access/accessToken";


//apply base url for axios
// const API_URL = "http://localhost:7000/";

// const API_URL = "https://3.144.45.14/api/"

const API_URL = "https://mdrengineapi.com/api/"

//pass new generated access token here
let userSessionCurr = ''

let token  = ''
let  axiosApi = axios.create({
  baseURL: API_URL,
});




// const refreshToken = async() =>{

//   const userSession = await JSON.parse(localStorage.getItem(sessionStorage))


//   // // console.log(userSession)
//   // if(userSession != null){
//   //   token = userSession.session.accessToken.jwtToken;
//   //   axiosApi.defaults.headers.common["Authorization"] = token;
//   //   }


//   if(userSession != null){

// const tokenExpiration = new Date(
//   userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
// );


// const isTokenExpired = tokenExpiration < new Date();


// if(isTokenExpired){
//   try {
//     const currentUserSession = await Auth.currentSession()
//     const currentUser = await Auth.currentAuthenticatedUser();
//     const currentSession = currentUser.signInUserSession;
//     currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
//       // do something with the new session
//       const sessionVal = {
//         session : session
//       }

      
//       localStorage.setItem(sessionStorage,JSON.stringify(sessionVal))

//       const sessionExp = {
//         sessionStartDate : moment().format(),
//         sessionExpiryDays : 2
//       }
//       localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp))

//       // userSessionCurr = JSON.parse(localStorage.getItem(sessionStorage))

// // console.log(userSessionCurr)

// // if(userSessionCurr != null){
// //   token = userSessionCurr.session.accessToken.jwtToken;
// //   }

// //   axiosApi = axios.create({
// //     baseURL: API_URL,
// //   });
  
// //   axiosApi.defaults.headers.common["Authorization"] = token;
  
// //   axiosApi.interceptors.response.use(
// //     (response) => response,
// //     (error) => Promise.reject(error)
// //   );

// userSessionCurr = JSON.parse(localStorage.getItem(sessionStorage))
// if(userSessionCurr != null){
//   token = userSessionCurr.session.accessToken.jwtToken;
//   }
  
//   return token;
//     });
//   } catch (e) {
//     // whatever
//     console.log(e)
//     window.location.href = '/logout'
//   }
// }else{


//   token = userSession.session.accessToken.jwtToken;
//   return token;

// }

// }else{
//   return token;
// }


//   // axiosApi.defaults.headers.common["Authorization"] = token;


// }


const refreshToken = async () => {
  // let token = null;
  // const userSession = JSON.parse(localStorage.getItem(sessionStorage));

  // if (userSession != null) {
  //   const tokenExpiration = new Date(
  //     userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
  //   );

  //   const isTokenExpired = tokenExpiration < new Date();

  //   if (isTokenExpired) {
      try {
        const currentUserSession = await Auth.currentSession();
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = currentUser.signInUserSession;

        await currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
          if (err) {
            console.log(err);
            window.location.href = '/logout';
          } else {
            const sessionVal = {
              session: session,
            };

            localStorage.setItem(sessionStorage, JSON.stringify(sessionVal));

            const sessionExp = {
              sessionStartDate: moment().format(),
              sessionExpiryDays: 2,
            };
            localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp));

            const userSessionCurr = JSON.parse(localStorage.getItem(sessionStorage));
            if (userSessionCurr != null) {
              token = userSessionCurr.session.accessToken.jwtToken;
            }
            // return token;
          }
        });
      } catch (e) {
        console.log(e);
        window.location.href = '/logout';
      }
  //   } else {
  //     token = userSession.session.accessToken.jwtToken;
  //   }
  // }else{
  //   // window.location.href = '/logout';
  // }

  // return token;
};

 
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
  if (error.response) {
    // Extract the error message from the response
    console.log(error.response)
    const errorMessage = error.response.data.message || 'Something went wrong, try again later';

    // Create a new error object with the custom message
    const customError = new Error(errorMessage);

    // Throw the custom error
    throw customError;
  }

  // If the error does not have a response, rethrow the original error
  throw error;
}
);


export async function get(url, config = {}) {
  // console.log('in get api call')
  // refreshToken().then(async () => {
    //  refreshToken()
    // // return await axiosApi
    // // .get(url, { ...config })
    // // .then((response) =>  response.data);
    let token = null;

  const userSession = JSON.parse(localStorage.getItem(sessionStorage));

  if (userSession != null) {
    const tokenExpiration = new Date(
      userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
    );

    const isTokenExpired = tokenExpiration < new Date();

    if(isTokenExpired){

      window.location.href = '/logout';

      // console.log('token exp')
       await refreshToken();

       const userSession2 = JSON.parse(localStorage.getItem(sessionStorage));
       token = userSession2.session.accessToken.jwtToken;
      // get(url, config = {})
    }else{
      // console.log('not')
      token = userSession.session.accessToken.jwtToken;
    }

      // token = await refreshToken();

      try {
      axiosApi.defaults.headers.common["Authorization"] = token;
      const response = await axiosApi.get(url, { ...config });
      return response.data;
    } 
    catch (error) {
      // Handle any errors here
      // console.log(error);
      throw error;
    }
    


  }


  
// }).catch((error) => {
//   // Handle any errors that occurred during the refreshToken call
//   console.error('Error refreshing token:', error);
// });

}

export async function post(url, data, config = {}) {
  // refreshToken()
  // return axiosApi
  //   .post(url, { ...data }, { ...config })
  //   .then((response) => response.data);

 

    // if (!token) {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       post(url, data, config = {})

    //     }, 1000); // Delay the recursive call by 1 second
    //   });
    // }
      // Keep checking if the token is fetched
  // while (!token) {
  //   token = await refreshToken();
  // }

  let token = null;

  const userSession = JSON.parse(localStorage.getItem(sessionStorage));

  // console.log(url)

  if (userSession != null) {
    const tokenExpiration = new Date(
      userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
    );

    const isTokenExpired = tokenExpiration < new Date();

    if(isTokenExpired){

      window.location.href = '/logout';
      // console.log('token exp')
      // await refreshToken();

      // const userSession2 = JSON.parse(localStorage.getItem(sessionStorage));
      //  token = userSession2.session.accessToken.jwtToken;
      // get(url, config = {})

      try {
        // Refresh the token
        await refreshToken();
        const userSession2 = JSON.parse(localStorage.getItem(sessionStorage));
        token = userSession2.session.accessToken.jwtToken;

        // Update the Authorization header with the refreshed token
        axiosApi.defaults.headers.common["Authorization"] = token;
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        throw refreshError;
      }

    }else{
      // console.log('not')
      token = userSession.session.accessToken.jwtToken;
    }

  
  try {
  // console.log(token)
    axiosApi.defaults.headers.common["Authorization"] = token;
    const response = await axiosApi.post(url, { ...data }, { ...config });
    return response.data;

  } catch (error) {
    // Handle any errors here
    // console.log(error.message);
    // return error.message;
    throw error;
  }
  
}else{
  if(url == '/update_user_signup_status' || url == '/check_user_email_exists'){

    try {
      // console.log(token)
        axiosApi.defaults.headers.common["Authorization"] = '';
        const response = await axiosApi.post(url, { ...data }, { ...config });
        return response.data;
    
      } catch (error) {
        // Handle any errors here
        // console.log(error.message);
        // return error.message;
        throw error;
      }
      
  }
}
}

export async function put(url, data, config = {}) {
  // refreshToken()
  // return axiosApi
  //   .put(url, { ...data }, { ...config })
  //   .then((response) => response.data);

  let token = null;

  const userSession = JSON.parse(localStorage.getItem(sessionStorage));

  if (userSession != null) {
    const tokenExpiration = new Date(
      userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
    );

    const isTokenExpired = tokenExpiration < new Date();

    if(isTokenExpired){
      // console.log('token exp')
      token = await refreshToken();
      // get(url, config = {})
    }else{
      // console.log('not')
      token = userSession.session.accessToken.jwtToken;
    }

  try {
    axiosApi.defaults.headers.common["Authorization"] = token;
    const response = await axiosApi.put(url, { ...data }, { ...config });
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.log(error);
    throw error;
  }

} 
}

export async function del(url, config = {}) {
  // refreshToken()
  // return await axiosApi
  //   .delete(url, { ...config })
  //   .then((response) => response.data);


  let token = null;

  const userSession = JSON.parse(localStorage.getItem(sessionStorage));

  if (userSession != null) {
    const tokenExpiration = new Date(
      userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
    );

    const isTokenExpired = tokenExpiration < new Date();

    if(isTokenExpired){
      // console.log('token exp')
      token = await refreshToken();
      // get(url, config = {})
    }else{
      // console.log('not')
      token = userSession.session.accessToken.jwtToken;
    }

  try {
    axiosApi.defaults.headers.common["Authorization"] = token;
    const response = await axiosApi.delete(url, { ...config });
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.log(error);
    throw error;
  }
} 
  
}
