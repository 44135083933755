import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, resetUser } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  checkUserEmailExists,
  updateUserSignupStatus
} from "../../../helpers/backend_helper"
import { Auth } from 'aws-amplify';
import moment from "moment"
import axios from 'axios';
import { getTspToken } from "helpers/getTspToken"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  // console.log("using the following url for registration: ")
  // console.log(user)
  // try {

     const  data = {
          email : user.email
        }

  try {
    const response = yield call(checkUserEmailExists, data)
    // console.log(response)
    // yield put(getPhonecodeListSuccess(response.data))

    try{
      const signupResponse = yield Auth.signUp({
        username: user.username,
        password: user.password,
        attributes : {
          email : user.email
        }
      })

      const  user_log_data = {
        email : user.email,
        username : user.username,
        updatedon : moment().format()
      }

try {
  // console.log(user_log_data)
  const Statusresponse = yield call(updateUserSignupStatus, user_log_data)

  getTspToken().then((tspToken) =>{
  console.log(Statusresponse.data)
  let tspData = {}
  if(Statusresponse.data == 0){
//public user
     tspData = {
      "name": user.username,
      "display_name": user.username,
      "email": user.email,
      "account_type": "LOCAL_USER",
      "account_status": "ACTIVE",
      "visibility": "NON_SHARABLE",
      "notify_on_share": true,
      "show_onboarding_experience": true,
      "onboarding_experience_completed": false,
      "password" : "WelcomeUser123!",
      "group_identifiers": [
        "6571364b-55e3-47a3-bc51-29f4fe784104"
      ]
    }
  

  }else{

//corporate user
    tspData = {
      "name": user.username,
      "display_name": user.username,
      "email": user.email,
      "account_type": "LOCAL_USER",
      "account_status": "ACTIVE",
      "visibility": "NON_SHARABLE",
      "notify_on_share": true,
      "show_onboarding_experience": true,
      "onboarding_experience_completed": false,
      "password" : "WelcomeUser123!",
      "group_identifiers": [
        "8f15a112-4867-4419-bae0-2ada5ea6174c"
      ]
    }

  }

  try {
    const Createresponse = axios.post('https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/create', tspData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer '+tspToken
      }
    });
    console.log(Createresponse.data);
  } catch (error) {
    console.error('Error creating user:', error);
  }
})
  yield put(registerUserSuccessful({...signupResponse, user_email : user.email}))



} catch (error) {
  // console.log("There was an error registering: ", error)
  yield put(registerUserFailed(error.message))
}

  
  } catch (error) {
    // console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error.message))
  }

} catch (error) {
    // console.log(error)
    yield put(registerUserFailed(error.message))
}
   
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
