import { authUser, sessionExpiration, sessionStorage } from "helpers/dataConfig";
import React, {useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserPersonalDetails as onGetUserPersonalDetails
} from "store/actions"; 

const Authmiddleware = (props) => {

  const dispatch = useDispatch();

  useEffect(()=>{

    // console.log(process.env.REACT_APP_SESSION_EXP_LIMIT)
    // refreshToken()
    // refreshToken()
    // getUserDetails()

  }, [dispatch])


  const getUserDetails = () =>{
    // console.log('in user details')
  
    // dispatch(onGetUserPersonalDetails());
  }


  const refreshToken = async() =>{

      const userSession = JSON.parse(localStorage.getItem(sessionStorage))

    console.log(userSession.session.accessToken)

    const tokenExpiration = new Date(
      userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
    );

    // Compare the token expiration with the current time
    const isTokenExpired = tokenExpiration < new Date();

    // console.log(isTokenExpired)

    if(isTokenExpired){
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = currentUser.signInUserSession;
        currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
          // do something with the new session
          const sessionVal = {
            session : session
          }
          
          // localStorage.setItem(sessionStorage,JSON.stringify(sessionVal))

          const sessionExp = {
            sessionStartDate : moment().format(),
            sessionExpiryDays : 2
          }
          // localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp))
        });
      } catch (e) {
        // whatever
      }
    }
    
  }

  const isTokenExpired = () =>{
      
    const exp = JSON.parse(localStorage.getItem(sessionExpiration))
    if(moment(exp.sessionStartDate).isSameOrBefore(moment().format())){
      return false
    }else{
      return true
    }

  }

  if (!localStorage.getItem(authUser) || !isTokenExpired) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
